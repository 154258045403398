<template>
    <div class="winter_olympics">
        <section class="olympics_banner">
            <img src="https://www.symuseum.cn/data/uploads/attachment/20220211/666banner.png" alt="">
        </section>
        <section class="activity_rules">
<!--            <h3>活动规则：</h3>-->
            <p>元宵节，又称上元节、小正月、元夕或灯节，是春节之后的第一个重要节日。正月是农历的元月，古人称夜为"宵"，所以把一年中第一个月圆之夜正月十五称为元宵节。元宵之夜，大街小巷张灯结彩，人们逛灯会、猜灯谜、赏花灯、放烟花，将从除夕开始延续的庆祝活动推向又一个高潮。</p>
            <p>古往今来文人墨客对元宵节格外青睐和眷恋，那些藏在古诗词中的绝美意象，惊艳了时光，也惊叹了心灵！</p>
            <p>请以下被选中参加展示的小朋友或家长于2月28日前到顺义区博物馆前台领取精美礼品一份！联系电话：81475286—1</p>
<!--            <span @click="showDialog">查看详情></span>-->
        </section>
        <section class="olympics_content">
            <van-tabs v-model="active">
                <van-tab title="优秀作品">
                    <ul class="olympics_content_list">
                        <li v-for="(item,index) in hotList" :key="index" @click="toDetails(item)">
                            <div class="list_img">
                                <img v-lazy="item.image" alt="">
                                <div class="list_mask">
                                    <p>{{item.titile}}-{{item.author}}</p>
                                </div>
                            </div>
                            <div class="list_dec">
                                <p>{{item.name}}</p>
                                <p>{{item.age}}</p>
                            </div>
                        </li>
                    </ul>
                </van-tab>
            </van-tabs>
        </section>
    </div>
</template>

<script>
    import { Dialog } from 'vant'
    export default {
        name: 'lantern',
        data () {
            return {
                active: 1,
                hotList: [
                    {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/1.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/1.mp4",
                    "titile": "青玉案·元夕",
                    "author": "辛弃疾",
                    "desc": "希望博物馆能给我们带来更多精彩的展览和活动。",
                    "name": "杨靖轩",
                    "sex": "男",
                    "age": "10岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/2.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/2.mp4",
                    "titile": "水调歌头•明月几时有",
                    "author": "苏轼",
                    "desc": "愿2022平安喜乐，万事顺意。新年新气象，世界无冠状！也祝我们顺义区的文化发展越来越好！",
                    "name": "卢嬿羽",
                    "sex": "女",
                    "age": "7岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/3.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/3.mp4",
                    "titile": "正月十五夜",
                    "author": "苏味道",
                    "desc": "2022年，希望更多的顺义人走进顺义博物馆，了解更多顺义文化，更加热爱顺义。",
                    "name": "刘家一",
                    "sex": "女",
                    "age": "9岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/4.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/4.mp4",
                    "titile": "元宵",
                    "author": "唐寅",
                    "desc": "希望顺义区文化馆开展更多这样的活动，丰富大家文化生活，祝愿大家元宵节快乐～",
                    "name": "于轻跃",
                    "sex": "女",
                    "age": "10岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/5.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/5.mp4",
                    "titile": "上元竹枝词",
                    "author": "符曾",
                    "desc": "在这个团圆的日子里，祝大家元宵节快乐，新的一年福旺、财旺、运更旺！也希望顺义区博物馆发展的越来越好，新的一年给顺义人民带来更多的活动和展出，加油！",
                    "name": "张绍宸",
                    "sex": "(保密)",
                    "age": "8岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/6.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/6.mp4",
                    "titile": "元宵",
                    "author": "唐寅",
                    "desc": "祝顺义区博物馆越办越好，2022，我们一起向未来！",
                    "name": "张莞尔",
                    "sex": "女",
                    "age": "7岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/7.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/7.mp4",
                    "titile": "元日",
                    "author": "王安石",
                    "desc": "希望疫情早点结束",
                    "name": "崔馨逸",
                    "sex": "女",
                    "age": "7岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/8.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/8.mp4",
                    "titile": "正月十五夜",
                    "author": "苏味道",
                    "desc": "大爱北京，大美顺义",
                    "name": "范英泽",
                    "sex": "(保密)",
                    "age": "10岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/9.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/9.mp4",
                    "titile": "正月十五月儿明",
                    "author": "北京童谣",
                    "desc": "希望自己学习更多文史知识以最佳状态为观众朋友们提供讲解服务",
                    "name": "刘雨萱",
                    "sex": "女",
                    "age": "10岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/10.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/10.mp4",
                    "titile": "青玉案•元夕",
                    "author": "辛弃疾",
                    "desc": "伴着顺义文化中心，走过了孩子的童年。博物馆的识古通今，图书馆的书海遨游，让一个懵懂幼儿长成翩翩学生。希望这里越办越好，一起见证共同的成长！",
                    "name": "谢丞畦",
                    "sex": "男",
                    "age": "6岁半"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/11.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/11.mp4",
                    "titile": "正月十五夜",
                    "author": "张祜",
                    "desc": "值此元宵佳节，借这首七言绝句祝福祖国山河无恙，祝福奥运健儿取得佳绩！",
                    "name": "孙正阳",
                    "sex": "男",
                    "age": "10岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/12.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/12.mp4",
                    "titile": "元日",
                    "author": "王安石",
                    "desc": "希望顺义博物馆多做一些关于文物历史类的研学活动，祝福顺义博物馆越来越好，在虎年里多多举办新的展览，我和同学们一定多多去参观！",
                    "name": "段梓芃",
                    "sex": "男",
                    "age": "8岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/13.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/13.mp4",
                    "titile": "正月十五夜",
                    "author": "张祜",
                    "desc": "在这个辞旧迎新，阖家团圆的日子里，我希望新的一年疫情早日结束，那些奋战在抗疫一线的工作人员和为了全民防疫而不能归乡游子们，愿你们能早日与家人团聚。",
                    "name": "李禹嘉",
                    "sex": "女",
                    "age": "8岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/14.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/14.mp4",
                    "titile": "生查子",
                    "author": "欧阳修",
                    "desc": "虎年吉祥 蒸蒸日上",
                    "name": "李明睿",
                    "sex": "男",
                    "age": "8岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/15.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/15.mp4",
                    "titile": "正月十五夜",
                    "author": "张祜",
                    "desc": "祝愿全体老师和同学们在新的一年里，工作顺利，学习进步！也祝愿顺义图书馆能越办越好！成为我们生活中的好朋友，陪着你我一起成长！",
                    "name": "魏千宸",
                    "sex": "男",
                    "age": "7岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/16.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/16.mp4",
                    "titile": "古朗月行",
                    "author": "李白",
                    "desc": "2022年祝所有的中华儿女新年快乐，虎虎生威，虎年大吉！祝福中国的健儿们勇创佳绩！祝福顺义越来越美！祝福顺义博物馆的所有工作人员万事如意！祝福顺义区文化发展越来越丰富！",
                    "name": "许思锘",
                    "sex": "女",
                    "age": "5岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/17.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/17.mp4",
                    "titile": "元日",
                    "author": "王安石",
                    "desc": "祝全区所有的小朋友大朋友新年快乐！虎虎生威！如虎添翼！健康幸福！",
                    "name": "孙文",
                    "sex": "男",
                    "age": "7岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/18.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/18.mp4",
                    "titile": "正月十五夜",
                    "author": "苏味道",
                    "desc": "平安快乐",
                    "name": "秦嘉阳",
                    "sex": "男",
                    "age": "9岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/19.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/19.mp4",
                    "titile": "正月十五夜",
                    "author": "张祜",
                    "desc": "新春佳节刚过，冬奥已拉开序幕，顺义大街小巷洋溢着节日的气氛和对冬奥会的热爱。元宵佳节将至，愿顺义区的文明发展更上一层楼",
                    "name": "林子辰",
                    "sex": "男",
                    "age": "6岁"
                }, {
                    "image": "https://www.symuseum.cn/data/uploads/attachment/20220211/20.jpg",
                    "video": "https://www.symuseum.cn/data/uploads/attachment/20220211/20.mp4",
                    "titile": "正月十五夜",
                    "author": "苏味道",
                    "desc": "祝：顺义文化活动越办越好！文艺节目丰富多彩！",
                    "name": "张嘉祺",
                    "sex": "女",
                    "age": "13岁"
                }],
                info: {}
            }
        },
        mounted () {
            // this.votingList(1)
        },
        methods: {
            // 活动规则详情
            showDialog () {
                Dialog.alert({
                    title: '活动规则',
                    message: '        元宵节，又称上元节、小正月、元夕或灯节，是春节之后的第一个重要节日。正月是农历的元月，古人称夜为"宵"，所以把一年中第一个月圆之夜正月十五称为元宵节。元宵之夜，大街小巷张灯结彩，人们逛灯会、猜灯谜、赏花灯、放烟花，将从除夕开始延续的庆祝活动推向又一个高潮。\n' +
                             '        古往今来文人墨客对元宵节格外青睐和眷恋，那些藏在古诗词中的绝美意象，惊艳了时光，也惊叹了心灵！\n' +
                             '        请以下被选中参加展示的小朋友或家长于2月28日前到顺义区博物馆前台领取精美礼品一份！联系电话：81475286—1',
                    messageAlign: 'left'
                }).then(() => {
                    // on close
                })
            },
            toDetails (item) {
                this.$router.push('/lanternDetails')
                window.sessionStorage.setItem('detailInfo',JSON.stringify(item))
            }
        }
    }
</script>

<style lang="less" scoped>
    .winter_olympics {
        width: 100%;
        margin: auto;
        background: #f0f5f8;
        text-align: left;
    .olympics_banner {
    img {
        width: 100%;
        border-radius: 0;
    }
    }
    .activity_rules {
        padding: 25px 25px;
    h3 {
        font-size: 16px;
        color: #000;
        margin-bottom: 10px;
    }
    p {
        font-size: 14px;
        color: #646364;
        line-height: 20px;
        text-indent: 28px;
    }
    span {
        display: block;
        text-align: right;
        color: #959594;
        margin-top: 10px;
        cursor: pointer;
    }
    }
    .list {
        padding: 0 .1rem .1rem .1rem;
    .list_title {
        font-size: .36rem;
        font-family: STKaiti;
        color: #1059d4;
        font-weight: bold;
    }
    ul {
        margin-top: .2rem;
        display: flex;
        justify-content: flex-start;
    li:last-child {
        margin-right: 0;
    }
    li {
        width: 32.5%;
        margin-right: .5%;
        background: #fff;
        border-radius: .05rem;
        position: relative;
    &>img {
         width: .3rem;
         position: absolute;
         top: .02rem;
         left: .03rem;
         z-index: 1;
     }
    .list_img {
        position: relative;
    img {
        width: 100%;
    }
    img[v-lazy="loading"] {
        width: 100%;
    }
    .list_mask {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        background: linear-gradient(transparent 60%, #000);
        justify-content: space-between;
        align-items: flex-end;
        top: 0;
        left: 0;
    p {
        font-size: .12rem;
        color: #fff;
        margin-bottom: .05rem;
        padding: 0 .01rem;
    }
    }
    }
    .list_dec {
        display: flex;
        height: .3rem;
        align-items: center;
        padding: 0 .05rem;
    img {
        width: .2rem;
        height: .2rem;
    }
    p {
        font-size: .12rem;
        margin-left: .1rem;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    }
    }
    }
    }
    .olympics_content {
        padding-bottom: .2rem;
    /deep/.van-tab {
        background: #f0f5f8;
    }
    /deep/.van-tabs__line {
        width: 55px;
        background: #1059d4;
    }
    .olympics_content_list {
        padding: 0 .1rem;
        margin-top: .2rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    li {
        cursor: pointer;
        width: 49%;
        border-radius: .05rem;
        margin-top: 10px;
        background: #fff;
    .list_img {
        position: relative;
    img {
        width: 100%;
    }
    .list_mask {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        top: 0;
        left: 0;
        background: linear-gradient(transparent 60%, #000);
    p {
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
        padding: 0 .05rem;
        text-indent: 10px;
    }
    }
    }
    .list_dec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
    img {
        width: .2rem;
        height: .2rem;
    }
    p {
        font-size: 14px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    }
    }
    }
    }
    }
    @media screen and (min-width: 1080px) and (max-width: 1920px) {
        .winter_olympics {
            width: 750px;
            margin: auto;
        img[lazy="error"] {
            width: 100%;
            height: 242px;
        }
        .olympics_banner {
        img {
            border-radius: 0;
        }
    }
    .list {
    ul {
    .list_img {
    img[lazy="error"] {
        width: 100%;
        height: 162px;
    }
    }
    }
    }
    }
    }
</style>
